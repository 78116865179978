<template>
	<div id="box">
		<div id="bannerbgc">
			<div id="bannercol">
				<p>融恒i style="font-size: 50px;">●</i>汽修汽配</p>
				<span>汽 修 汽 配 解 决 方 案</span>
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/dowm.png">
			</div>
		</div>
		<div id="main_hexagon">
			<div id="main_hexagon_box">
				<p id="title">
					融恒修汽配系统是由融融恒海）科技有限公司独立开发的新一代收银系统。针对汽修汽配服务性的特点，融恒融恒修汽配专注于优化商户管理，提高服务效率，深度挖掘会员价值，让您赚钱管理更轻松。</p>
				<div id="lin"></div>
				<div id="subhead">
					<p id="subhead_tit">融恒修汽配应用范围</p>
					<p id="text">适应各种业态经营场景，让经营管理更加轻松</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/qxliujiao.jpg">
				</div>
			</div>
		</div>
		<div id="flow">
			<p id="tit">融恒修汽配生态流程</p>
			<span id="sub">适应各种业态经营场景，让经营管理更加轻松</span>
			<div id="flow_tit_flex">
				<div id="flow_tit_flex_box">
					<p>顾客到店</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/pating.png">
				</div>
				<div id="flow_tit_flex_box">
					<p>开始服务</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/pating.png">
				</div>
				<div id="flow_tit_flex_box">
					<p>付款离开</p>
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/pating.png">
				</div>
			</div>
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/qxflow.jpg">
		</div>
		<div id="Mbbox">
			<div id="MBtit">
				<p>融恒修汽配解决方案</p>
				<span>适应各种业态经营场景，让经营管理更加轻松</span>
			</div>
			<div id="subimg">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYMB.png">
				<p>会员</p>
				<div id="flex_box">
					<div id="box">
						<p>会员制度</p>
						<li>●自由设置会员等级</li>
						<li>●多样化的会员权益</li>
						<li>●会员关怀生日馈赠</li>
					</div>
					<div id="box">
						<p>会员积分</p>
						<li>●花样积分任务任选</li>
						<li>●多种积分销号方式</li>
						<li>●风控体系保障安全</li>
					</div>
					<div id="box">
						<p>引流拓客</p>
						<li>●多种渠道引进会员</li>
						<li>●活动促进会员裂变</li>
						<li>●推广员增加覆盖率</li>
					</div>
					<div id="box">
						<p>精准营销</p>
						<li>●多种会员营销活动</li>
						<li>●会员回访获取反馈</li>
						<li>●会员账户状态提醒</li>
					</div>
				</div>
				<div id="My_box_bot">
					<div id="My_box_bot_flex">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/004.jpg" style='border-right:2px solid #ddd;

            border-image: -webkit-linear-gradient(#4D79A7, #8854CC,#4D79A7) 20 20;

            border-image: -moz-linear-gradient(#4D79A7, #8854CC,#4D79A7) 20 20;

            border-image: -o-linear-gradient(#4D79A7, #8854CC,#4D79A7) 20 20;

            border-image: linear-gradient(#4D79A7, #8854CC,#4D79A7) 20 20;'>
						<div id="myt">
							<p>会员画像</p>
							<span>全方位分析会员信息，会员生日、消费习惯多维度筛选，潜水会员、流失会员、活跃会员等一手掌握。</span>
						</div>
					</div>
					<div id="My_box_bot_flex">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYMBIMG02.jpg">
						<div id="myt">
							<p>精准营销</p>
							<span>支持三十五种会员筛选维度，帮你精准分析会员，准确推送。真正做到千人千面，精耕细作，让门店会员持续活跃。</span>
						</div>
					</div>
				</div>

			</div>

		</div>
		<div id="MYmake_box">
			<div id="MYmake_box_header">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYYY.png">
				<p>预约</p>
				<span>多元化预约模式，人性化客户服务</span>
			</div>
			<div id="MYmake_box_main">
				<div id="MYmake_box_main_flex">
					<div id="MYmake_box_main_flex_box">
						<div id="MYmake_box_main_flex_box_top">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYRY.png">
							<div id="MYmake_box_main_flex_box_top_box">
								<p
									style="border-radius: 10px 0px 0 10px; background-color: #FFFFFF; border: 2px solid #fd3f31;color: #fd3f31;">
									早班</p>
								<p style="border-radius: 0px 10px 10px 0px; background-color: #fd3f31;color: #FFFFFF;">
									晚班</p>
							</div>
						</div>
						<div id="MYmake_box_main_flex_box_top">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYRY.png">
							<div id="MYmake_box_main_flex_box_top_box">
								<p style="border-radius: 10px 0px 0 10px; background-color: #fd3f31;color: #FFFFFF;">早班
								</p>
								<p
									style="border-radius: 0px 10px 10px 0px; background-color: #FFFFFF; border: 2px solid #fd3f31;color: #fd3f31;">
									晚班</p>
							</div>
						</div>
					</div>
					<span>后台预排班</span>
				</div>
				<div id="MYmake_box_main_flex">
					<div id="MYmake_box_main_flex_box">
						<div id="MYmake_box_main_flex_box_top">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYRY.png">
							<div id="MYmake_box_main_flex_box_top_box">
								<p
									style="border-radius: 10px 0px 0 10px; background-color: #FFFFFF; border: 2px solid #fd3f31;color: #fd3f31;">
									早班</p>
								<p style="border-radius: 0px 10px 10px 0px; background-color: #fd3f31;color: #FFFFFF;">
									晚班</p>
							</div>
						</div>
						<div id="usinfo">
							<p><i class="el-icon-phone"></i>4006182019</p>
							<p><i class="el-icon-location"></i>The auto parts(汽修汽配)</p>
						</div>
						<!-- <div id="MYmake_box_main_flex_box_top">
								<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MYRY.png" >
								<div id="MYmake_box_main_flex_box_top_box">
									<p style="border-radius: 10px 0px 0 10px; background-color: #fd3f31;color: #FFFFFF;">早班</p>
									<p style="border-radius: 0px 10px 10px 0px; background-color: #FFFFFF; border: 2px solid #fd3f31;color: #fd3f31;">晚班</p>
								</div>
						</div> -->
					</div>
					<span>手机预约</span>
				</div>
				<div id="MYmake_box_main_flex">
					<div id="MYmake_box_main_flex_box">
						<div id="dis">
							<div id="msg_left">
								<i class="el-icon-warning-outline"></i>
							</div>
							<div id="msg_right">
								<p>您有新的预约</p>
								<p>请及时处理！</p>
								<button type="button">立即接单</button>
							</div>
						</div>
					</div>
					<span>前台自动提醒</span>
				</div>
			</div>
		</div>
		<div id="YGbox">
			<div id="YGbox_main">
				<div id="YGbox_main_header">
					<i class="el-icon-user"></i>
					<p>员工</p>
					<span>更多商机，工作更便捷；多维度提成，留住价值员工</span>
				</div>
				<div id="YGbox_main_top">
					<div id="img">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YJ002.jpg">
					</div>
					<div id="YGbox_main_top_right">
						<div id="YGbox_main_top_right_header">
							<p>随时开工</p>
							<span>为门店创造更多价值</span>
						</div>
						<div id="YGbox_main_top_right_main">
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-edit"></i>
								<span>记录工作</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-bell"></i>
								<span>回访提醒</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-tickets"></i>
								<span>备忘事项</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-document-add"></i>
								<span>及时下单</span>
							</div>
						</div>
					</div>
				</div>
				<div id="YGbox_main_top">
					<div id="YGbox_main_top_right">
						<div id="YGbox_main_top_right_header">
							<p>记录成长</p>
							<span>鼓励员工良性竞争</span>
						</div>
						<div id="YGbox_main_top_right_main">
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-document-copy"></i>
								<span>业绩明细</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-coin"></i>
								<span>个人提成</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-s-data"></i>
								<span>销售明细</span>
							</div>
							<div id="YGbox_main_top_right_main_flex">
								<i class="el-icon-s-marketing"></i>
								<span>销售额排名</span>
							</div>
						</div>
					</div>
					<div id="img">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YG01.jpg">
					</div>
				</div>
			</div>
		</div>
		<div id="bgcbox">
			<div id="bgcbox_header">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/quan.png">
				<p>营销</p>
			</div>
			<div id="timecard_box">
				<p><span>多类型次卡 </span> 单品卡、套餐次卡、类别储值卡、月卡、年卡全支持，类型自定义</p>
				<div id="timacard">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/timecardimg.png">
				</div>
			</div>
			<div id="timecard_box" style="margin-top: 3%;">
				<p><span>多种营销活动 </span> - 促销活动概况</p>
				<div id="timacard" style="height: 200px;">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/quanimg.png">
				</div>
			</div>
			<div id="timecard_box" style="margin-top: 3%;">
				<p><span>多种营销活动 </span> - 促销活动概况</p>
				<div id="timacard" style="height: 400px;">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ZZT.png">
				</div>
			</div>
			<div id="timecard_box" style="margin-top: 3%;border-radius: 10px;">
				<p><span>多种营销活动 </span> - 促销活动概况</p>
				<div id="timacard" style="height: 400px;">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/cxTYPE.png">
				</div>
			</div>
		</div>
		<div id="bgcci">
			<div>

			</div>
		</div>
		<div id="WXBOX">
			<div id="WXBOX_header">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LIwu.png">
				<p>微店小程序+拓客</p>
				<span>团购 & 拼团 & 分销，通过社交分享开拓资源</span>
			</div>
			<div id="img_flex">
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/006.png">
					<p>团购商品</p>
				</div>
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/005.png">
					<p>团购详情</p>
				</div>
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Shop03.png">
					<p>邀请有礼</p>
				</div>
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Shop04.png">
					<p>社交分享</p>
				</div>
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Shop05.png">
					<p>会员中心</p>
				</div>
				<div id="img_flex_box">
					<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/005.png">
					<p>推广产品</p>
				</div>
			</div>
			<div id="WXBOX_bottom">
				<p class="tt">团购 & 拼团 & 分销，通过社交分享开拓资源</p>
				<div id="WXBOX_bottom_flex">
					<div id="WXBOX_bottom_LEFT">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/FX.png">
						<p>云端设置分享</p>
					</div>
					<div id="WXBOX_bottom_LEFT">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/DATA.png">
						<p>云端数据统计</p>
					</div>
				</div>
			</div>
		</div>
		<div id="onestore">
			<div id="onestore_main">
				<div id="onestore_main_header">
					<div id="lins">
					</div>
					<p>单门店 解决方案</p>
					<span>适应多种业态经营场景，让经营更加得心应手</span>
				</div>
				<div id="onestore_main_main">
					<div id="onestore_main_main_flex">
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/BB.png">
							<p>企业级进销存</p>
							<span>多种报表、员工排班</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/MD.png">
							<p>我的店铺（手机版）</p>
							<span>管理门店、销售数据、实时监控</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YGGL.png">
							<p>员工管理</p>
							<span>按比例提成、制定提成阶梯</span>
						</div>
					</div>
					<div id="immage">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/one.jpg">
					</div>
					<div id="onestore_main_main_flex" style="margin-top: -20px;">
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Mbcard.png">
							<p>会员卡</p>
							<span>次卡、购物卡、储值卡等</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YYicon.png">
							<p>线上预约</p>
							<span>手机在线预约，到期自动提醒等</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YX.png">
							<p>CRM营销管理</p>
							<span>销售业绩榜 + 精准营销 + 社交分享</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="onestore" style="margin-top: -2%;">
			<div id="onestore_main">
				<div id="ins">

				</div>
				<div id="onestore_main_header" style="padding-top: 5%;">
					<p>连锁店 解决方案</p>
					<span>为汽修汽配量身定做，智能系统分析，轻松管理门店</span>
				</div>
				<div id="onestore_main_main">
					<div id="onestore_main_main_flex">
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/copyshop.png">
							<p>一键复制门店</p>
							<span>轻松开分店，并实时查看所有连锁销 售数据</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/shopcj.png">
							<p>门店层级管理</p>
							<span>可设置区域门店管理，支持直营店和 加盟店管理</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ex.png">
							<p>企业级报表</p>
							<span>总部实时监控门店营业数据，商品库 存情况对比分析，科学调整销售策略</span>
						</div>
					</div>
					<div id="immage">
						<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/two.jpg">
					</div>
					<div id="onestore_main_main_flex" style="margin-top: -20px;">
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/card.png">
							<p>卡券通用</p>
							<span>优惠券、积分等跨门店通用，并统一 结算</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/hy.png">
							<p>会员共享</p>
							<span>各门店会员资料同步，会员可在任意 门店使用和消费</span>
						</div>
						<div id="onestore_main_main_flex_box">
							<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/HD.png">
							<p>门店活动同步</p>
							<span>总部可在后台统一发布活动，并及时同步各门店</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="right_box_float">
			<div id="right_box_float_box" @click="callfun">
				<icon-svg class='iconclas' iconClass='shouye'></icon-svg>
			</div>
			<div id="right_box_float_box">
				<a
					href="tencent://message/?Menu=yes&uin=472720628&Site=80fans&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a545b1714f9d45">
					<icon-svg class='iconclas' iconClass='qq'></icon-svg>
				</a>
				<!-- <a href="javascript:void(0)" id="contactQQ">在线客服</a> -->
			</div>
			<el-tooltip placement="left">
				<div slot="content">
					<img style="width: 100px;height: 100px;"
						src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/wx.png">
				</div>
				<div id="right_box_float_box">
					<icon-svg class='iconclas' iconClass='weixin1'></icon-svg>
				</div>
			</el-tooltip>
		</div>
		<div id="left_box_float">
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
					:type="activity.type" :color="activity.color" :size="activity.size">
					{{activity.content}}
				</el-timeline-item>
			</el-timeline>
		</div>
		<footers></footers>
	</div>
</template>
<script>
	import footers from '../FOOTER.vue'
	export default {
		components: {
			footers
		},
		data() {
			return {
				activities: [{
					content: '融恒业',
					color: '#8387dd'
				}, {
					content: '应用范围',
					color: '#8387dd'
				}, {
					content: '生态流程',
					color: '#8387dd'
				}, {
					content: '解决方案',
					color: '#8387dd'
				}, {
					content: '小程序拓客',
					color: '#8387dd'
				}, {
					content: '单门店',
					color: '#8387dd'
				}, {
					content: '连锁店',
					color: '#8387dd'
				}],
				name: ''
			}
		},
		mounted() {
			let clasname = document.getElementsByClassName('el-timeline-item__wrapper')
			window.addEventListener("scroll", this.handleScroll);
			for (let i = 0; i < clasname.length; i++) {
				clasname[i].onclick = () => {
					this.name = clasname[i].innerText
					if (this.name == '融恒业') {
						const returnEle = document.querySelector("#box"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '应用范围') {
						const returnEle = document.querySelector("#main_hexagon_box"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '生态流程') {
						const returnEle = document.querySelector("#flow"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '解决方案') {
						const returnEle = document.querySelector("#Mbbox"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '会员') {
						const returnEle = document.querySelector("#subimg"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '预约') {
						const returnEle = document.querySelector("#MYmake_box"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '员工') {
						const returnEle = document.querySelector("#YGbox"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '营销') {
						const returnEle = document.querySelector("#bgcbox_header"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '小程序拓客') {
						const returnEle = document.querySelector("#WXBOX"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '单门店') {
						const returnEle = document.querySelector("#lins"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
					if (this.name == '连锁店') {
						const returnEle = document.querySelector("#ins"); //productId是将要跳转区域的id
						if (!!returnEle) {
							returnEle.scrollIntoView(true); // true 是默认的
						}
					}
				}
			}
		},
		methods: {
			callfun() {
				window.location.href = 'tel:4006182019'
			},
			handleScroll() {
				//获取滚动时的高度
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				// 
				// console.log(scrollTop)
				if (scrollTop > 2400) {
					let arr = [{
						content: '融恒业',
						color: '#8387dd'
					}, {
						content: '应用范围',
						color: '#8387dd'
					}, {
						content: '生态流程',
						color: '#8387dd'
					}, {
						content: '解决方案',
						color: '#8387dd'
					}, {
						content: '会员',
						color: '#8387dd'
					}, {
						content: '预约',
						color: '#8387dd'
					}, {
						content: '员工',
						color: '#8387dd'
					}, {
						content: '营销',
						color: '#8387dd'
					}, {
						content: '小程序拓客',
						color: '#8387dd'
					}, {
						content: '单门店',
						color: '#8387dd'
					}, {
						content: '连锁店',
						color: '#8387dd'
					}]
					this.activities = arr
				}
				if (scrollTop > 8500 || scrollTop < 2400) {
					let arr = [{
						content: '融恒业',
						color: '#8387dd'
					}, {
						content: '应用范围',
						color: '#8387dd'
					}, {
						content: '生态流程',
						color: '#8387dd'
					}, {
						content: '解决方案',
						color: '#8387dd'
					}, {
						content: '小程序拓客',
						color: '#8387dd'
					}, {
						content: '单门店',
						color: '#8387dd'
					}, {
						content: '连锁店',
						color: '#8387dd'
					}]
					this.activities = arr
				}
				// if (scrollTop > oneHeight) {
				// 	//这是滑动到scrollTwo的距离要做的操作

				// }
				// if (scrollTop > twoHeight) {
				// 	//这是滑动到scrollThree要做的操作
				// }
			}

		},
		destroyed() {
			document.removeEventListener('scroll', this.handleScroll)
		}
	}
</script>

<style scoped="scoped" lang="less">
	@media screen and (min-width:720px) {

		//电脑
		#bannerbgc {
			width: 100%;
			height: 1500px;
			background-image: url(https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/qxbannerbgxc.jpg);
			background-size: 100% 100%;

			#bannercol {
				width: 100%;
				height: 100%;
				background-image: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
				text-align: center;
				color: #FFFFFF;

				p {
					font-size: 70px;
					padding-top: 35vh;
					font-weight: 600;
				}

				span {
					font-size: 30px;
				}

				img {
					display: block;
					margin-left: 50%;
					margin-top: 8%;
					animation: donghua 1.5s infinite;
				}

				@keyframes donghua {
					0% {
						transform: translate(0, 0px);
					}

					50% {
						transform: translate(0, 50px);
					}
				}
			}
		}
	}

	@media screen and(max-width:720px) {

		//手机
		#bannerbgc {
			width: 100%;
			height: 500px;
			background-image: url(https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/qxbannerbgxc.jpg);
			background-size: 100% 100%;

			#bannercol {
				width: 100%;
				height: 100%;
				background-image: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
				text-align: center;
				color: #FFFFFF;

				p {
					font-size: 40px;
					padding-top: 15vh;
					font-weight: 600;
				}

				span {
					font-size: 20px;
				}

				img {
					display: block;
					margin-left: 45%;
					margin-top: 8%;
					animation: donghua 1.5s infinite;
				}

				@keyframes donghua {
					0% {
						transform: translate(0, 0px);
					}

					50% {
						transform: translate(0, 50px);
					}
				}
			}
		}
	}

	@media screen and(min-width:720px) {

		//电脑
		#main_hexagon {
			width: 100%;
			height: 500px;
			position: relative;

			#main_hexagon_box {
				width: 60%;
				margin-left: 20%;
				height: 1200px;
				background-color: #FFFFFF;
				border-radius: 15px;
				position: absolute;
				top: -140%;

				#title {
					width: 80%;
					margin-left: 10%;
					margin-top: 8%;
					font-size: 22px;
				}

				#lin {
					width: 20px;
					height: 4px;
					border-radius: 10px;
					background-color: #fd3f31;
					margin: 0 auto;
					margin-bottom: 10%;
				}

				#subhead {
					width: 100%;
					margin: 0 auto;
					text-align: center;

					#subhead_tit {
						font-size: 36px;
						color: #fd3f31;
					}

					#text {
						font-size: 22px;
						line-height: 36px;
						text-align: center;
						color: #232323;
					}

					img {
						max-width: 100%;
						height: 60%;
						margin-top: 5%;
					}
				}
			}
		}
	}

	@media screen and (max-width:720px) {

		//手机
		#main_hexagon {
			width: 100%;
			height: 500px;
			position: relative;

			#main_hexagon_box {
				width: 100%;
				height: 900px;
				background-color: #FFFFFF;
				border-radius: 15px;
				position: absolute;
				top: -23%;

				#title {
					width: 80%;
					margin-left: 10%;
					margin-top: 8%;
					font-size: 22px;
				}

				#lin {
					width: 20px;
					height: 4px;
					border-radius: 10px;
					background-color: #fd3f31;
					margin: 0 auto;
					margin-bottom: 10%;
				}

				#subhead {
					width: 100%;
					margin: 0 auto;
					text-align: center;

					#subhead_tit {
						font-size: 36px;
						color: #fd3f31;
					}

					#text {
						font-size: 22px;
						line-height: 36px;
						text-align: center;
						color: #232323;
					}

					img {
						width: 100%;
						max-height: 60%;
						margin-top: 5%;
					}
				}
			}
		}
	}

	@media screen and (min-width:720px) {

		//电脑
		#flow {
			width: 60%;
			// margin-top: 5%;
			margin-left: 20%;
			height: 1000px;

			#tit {
				width: 80%;
				margin-left: 10%;
				padding-top: 8%;
				font-size: 22px;
				text-align: center;
				font-size: 36px;
				color: #fd3f31;
			}

			#sub {
				display: block;
				font-size: 22px;
				line-height: 36px;
				text-align: center;
				color: #232323;
			}

			#flow_tit_flex {
				width: 100%;
				height: 200px;
				margin-top: 2%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#flow_tit_flex_box {
					width: 33%;
					height: 100%;
					text-align: center;
					padding-top: 5%;

					p {
						color: #fd3f31;
						font-size: 18px;
						margin-bottom: 20px;
						font-weight: bold;
					}

					img {
						height: 25%;
						max-width: 9%;
						animation: donghua 1.5s infinite;
					}
				}
			}

			img {
				max-width: 100%;
				height: 55%;
			}

		}
	}

	@media screen and (max-width:720px) {

		//手机
		#flow {
			width: 100%;
			margin-top: 75%;
			height: 660px;
			

			#tit {
				width: 90%;
				margin-left: 5%;
				padding-top: 8%;
				font-size: 22px;
				text-align: center;
				font-size: 36px;
				color: #fd3f31;
			}

			#sub {
				display: block;
				font-size: 22px;
				line-height: 36px;
				text-align: center;
				color: #232323;
			}

			#flow_tit_flex {
				width: 100%;
				height: 200px;
				margin-top: 2%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#flow_tit_flex_box {
					width: 33%;
					height: 50%;
					text-align: center;
					padding-top: 5%;

					p {
						color: #fd3f31;
						font-size: 18px;
						margin-bottom: 20px;
						font-weight: bold;
					}

					img {
						height: 50%;
						width: 30%;
						animation: donghua 1.5s infinite;
					}
				}
			}

			img {
				width: 100%;
				height: 40%;
			}

		}
	}

	@media screen and (min-width:720px) {

		//电脑
		#Mbbox {
			margin-top: 5%;
			width: 60%;
			margin-left: 20%;
			height: 1200px;

			#MBtit {
				padding-top: 6%;
				text-align: center;

				p {
					font-size: 36px;
					color: #fd3f31;
				}

				span {
					font-size: 22px;
				}
			}

			#subimg {
				margin-top: 5%;
				width: 100%;
				height: 80%;
				text-align: center;

				img {
					// max-width: ;
					height: 8%;
					max-width: 7%;
				}

				p {
					font-size: 30px;
					color: #fd3f31;
				}

				#flex_box {
					width: 100%;
					height: 200px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					#box {
						width: 25%;
						height: 100%;

						p {
							color: #000000
						}
					}
				}

				#My_box_bot {
					width: 100%;
					height: 60%;
					margin-top: 3%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					#My_box_bot_flex {
						width: 50%;
						height: 100%;

						img {
							max-width: 100%;
							height: 70%;
						}

						#myt {
							width: 80%;
							margin-left: 10%;

							p {
								font-size: 25px;
								color: #000000;
								margin-top: 5%;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:720px) {

		//手机
		#Mbbox {
			margin-top: 5%;
			width: 100%;
			height: 1350px;

			#MBtit {
				padding-top: 6%;
				text-align: center;

				p {
					font-size: 36px;
					color: #fd3f31;
				}

				span {
					font-size: 22px;
				}
			}

			#subimg {
				margin-top: 5%;
				width: 100%;
				height: 80%;
				text-align: center;

				img {
					// max-width: ;
					// max-height: 10%;
					width: 20%;
				}

				p {
					font-size: 30px;
					color: #fd3f31;
				}

				#flex_box {
					width: 100%;
					height: 300px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#box {
						width: 50%;
						height: 50%;

						p {
							font-size: 24px;
							color: #000000
						}
						li{
							font-size: 14px;
						}
					}
				}

				#My_box_bot {
					width: 100%;
					height: 60%;
					margin-top: 3%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#My_box_bot_flex {
						width: 100%;
						height: 50%;
						margin-top: 5%;

						img {
							min-width: 60%;
							height: 60% !important;
							// height: 70%;
							border: none !important;
						}

						#myt {
							width: 80%;
							margin-left: 10%;

							p {
								font-size: 25px;
								color: #000000;
								margin-top: 5%;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:720px) {

		//电脑
		#MYmake_box {
			width: 100%;
			height: 600px;
			background-color: #f3f3f3;
			margin-top: 5%;

			#MYmake_box_header {
				width: 60%;
				height: 30%;
				text-align: center;
				margin-left: 20%;

				img {
					height: 50%;
					max-width: 8%;
				}

				p {
					color: #fd3f31;
					font-size: 36px;
					margin: 0;
				}

				span {
					font-size: 20px;
				}
			}

			#MYmake_box_main {
				width: 60%;
				height: 70%;
				margin-left: 20%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				#MYmake_box_main_flex {
					width: 30%;
					height: 100%;
					text-align: center;

					#MYmake_box_main_flex_box {
						height: 70%;
						width: 95%;
						background-color: #FFFFFF;
						margin-bottom: 10%;
						margin-top: 10%;
						border-radius: 20px;
						padding-top: 10%;
						transition: all .8s; 
						#MYmake_box_main_flex_box_top {
							width: 60%;
							margin-left: 20%;
							height: 40%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							img {
								height: 50%;
							}

							#MYmake_box_main_flex_box_top_box {
								width: 70%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;

								p {
									background-color: #FFFF00;
									width: 100%;
									height: 35%;
									display: flex;
									justify-content: center;
									align-items: center;
									margin: 0;
								}
							}

						}

						#usinfo {
							width: 80%;
							margin-left: 20%;
							text-align: left;

							p {
								color: #fd3f31;
								font-size: 18px;

								i {
									margin-right: 5%;
								}
							}
						}

						#dis {
							width: 90%;
							height: 100%;
							margin-left: 5%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							#msg_left {
								width: 40%;

								i {
									font-size: 140px;
									color: #fd3f31;
								}
							}

							#msg_right {
								width: 55%;
								text-align: left;

								p {
									font-size: 20px;
									margin: 0;
									color: #fd3f31;
								}

								button {
									cursor: default;
									background: linear-gradient(to right, #fd3f31, #fd712f);
									width: 60%;
									height: 35px;
									margin-top: 5%;
									border-radius: 30px;
									color: #FFFFFF;
								}
							}
						}
					}
					#MYmake_box_main_flex_box:hover{
						width: 100%;
						height: 72%;
					}
					span {
						font-size: 25px;
					}
				}
			}
		}

	}

	@media screen and (max-width:720px) {

		//手机
		#MYmake_box {
			width: 100%;
			height: 650px;
			background-color: #f3f3f3;
			margin-top: 10%;
			padding-top: 5%;

			#MYmake_box_header {
				width: 100%;
				height: 30%;
				text-align: center;

				img {
					height: 40%;
					// max-width: 8%;
				}

				p {
					color: #fd3f31;
					font-size: 30px;
					margin: 0;
				}

				span {
					font-size: 18px;
				}
			}

			#MYmake_box_main {
				width: 100%;
				height: 70%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				#MYmake_box_main_flex {
					width: 49%;
					height: 50%;
					text-align: center;

					#MYmake_box_main_flex_box {
						height: 70%;
						width: 100%;
						background-color: #FFFFFF;
						margin-bottom: 10%;
						margin-top: 10%;
						border-radius: 20px;
						padding-top: 10%;

						#MYmake_box_main_flex_box_top {
							width: 60%;
							margin-left: 20%;
							height: 40%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							img {
								height: 50%;
							}

							#MYmake_box_main_flex_box_top_box {
								width: 70%;
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;

								p {
									background-color: #FFFF00;
									width: 100%;
									height: 55%;
									display: flex;
									justify-content: center;
									align-items: center;
									margin: 0;
								}
							}

						}

						#usinfo {
							width: 80%;
							margin-left: 20%;
							text-align: left;

							p {
								color: #fd3f31;
								font-size: 12px;

								i {
									margin-right: 5%;
								}
							}
						}

						#dis {
							width: 90%;
							height: 100%;
							margin-left: 5%;
							display: flex;
							justify-content: space-between;
							align-items: center;

							#msg_left {
								width: 40%;

								i {
									font-size: 60px;
									color: #fd3f31;
								}
							}

							#msg_right {
								width: 55%;
								text-align: left;

								p {
									font-size: 14px;
									margin: 0;
									color: #fd3f31;
								}

								button {
									cursor: default;
									background: linear-gradient(to right, #fd3f31, #fd712f);
									width: 80%;
									height: 25px;
									margin-top: 5%;
									border-radius: 30px;
									color: #FFFFFF;
									font-size: 12px;
								}
							}
						}
					}

					span {
						font-size: 18px;
					}
				}
			}
		}

	}

	@media screen and (min-width:720px) {

		//电脑
		#YGbox {
			z-index: 99;
			width: 100%;
			height: 1500px;
			padding-top: 5%;

			#YGbox_main {
				width: 60%;
				margin-left: 20%;
				height: 100%;
				background-color: #FFFFFF;
				border-radius: 20px;

				#YGbox_main_header {
					width: 100%;
					height: 14%;
					text-align: center;

					i {
						font-size: 70px;
						color: #fd3f31;
					}

					p {
						font-size: 36px;
						color: #fd3f31;
					}

					span {
						font-size: 20px;
					}
				}

				#YGbox_main_top {
					width: 100%;
					height: 43%;
					text-align: center;
					display: flex;

					#img {
						width: 50%;
						height: 100%;

						img {
							height: 100%;
							max-width: 100%;
						}
					}

					#YGbox_main_top_right {
						width: 50%;
						height: 100%;

						#YGbox_main_top_right_header {
							text-align: left;
							margin-top: 6%;
							width: 60%;
							margin-left: 20%;

							p {
								font-size: 30px;
								color: #fd3f31;
							}

							span {
								font-size: 20px;
							}
						}

						#YGbox_main_top_right_main {
							width: 60%;
							margin-left: 20%;
							height: 75%;
							margin-top: 4%;

							#YGbox_main_top_right_main_flex {
								width: 100%;
								text-align: left;
								height: 20%;
								display: flex;
								align-items: center;

								i {
									display: block;
									width: 60px;
									height: 60px;
									background: linear-gradient(to right top, #975BCC, #4D79A7);
									text-align: center;
									line-height: 60px;
									font-size: 30px;
									border-radius: 50%;
									color: #FFFFFF;
									margin-right: 8%;
								}

								span {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:720px) {

		//手机
		#YGbox {
			z-index: 99;
			width: 100%;
			height: 1075px;
			padding-top: 5%;
			margin-top: 5%;
		
			#YGbox_main {
				width: 100%;
				height: 100%;
				background-color: #FFFFFF;
				border-radius: 20px;
		
				#YGbox_main_header {
					width: 100%;
					height: 14%;
					text-align: center;
		
					i {
						font-size: 55px;
						color: #fd3f31;
					}
		
					p {
						font-size: 30px;
						color: #fd3f31;
					}
		
					span {
						font-size: 18px;
					}
				}
		
				#YGbox_main_top {
					width: 100%;
					height: 43%;
					margin-top: 8%;
					text-align: center;
					display: flex;
					
					#img {
						width: 46%;
						height: 100%;
						img {
							// height: 100%;
							max-width: 100%;
						}
					}
		
					#YGbox_main_top_right {
						width: 50%;
						height: 100%;
						margin-left: 4%;
		
						#YGbox_main_top_right_header {
							text-align: left;
							margin-top: 6%;
							width: 100%;
		
							p {
								font-size: 25px;
								color: #fd3f31;
							}
		
							span {
								font-size: 18px;
							}
						}
		
						#YGbox_main_top_right_main {
							width: 100%;
							height: 80%;
							margin-top: 4%;
		
							#YGbox_main_top_right_main_flex {
								width: 100%;
								text-align: left;
								height: 20%;
								display: flex;
								align-items: center;
		
								i {
									display: block;
									width: 60px;
									height: 60px;
									background: linear-gradient(to right top, #975BCC, #4D79A7);
									text-align: center;
									line-height: 60px;
									font-size: 30px;
									border-radius: 50%;
									color: #FFFFFF;
									margin-right: 8%;
								}
		
								span {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:720px) {

		//电脑
		#bgcbox {
			width: 100%;
			height: 2300px;
			background-image: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
			position: relative;
			top: -615px;
			z-index: -1;
			padding-top: 650px;

			#bgcbox_header {
				width: 60%;
				height: 200px;
				margin-left: 20%;
				text-align: center;

				img {
					height: 50%;
					max-width: 10%;
				}

				p {
					font-size: 30px;
					color: #FFFFFF;
				}
			}

			#timecard_box {
				width: 60%;
				margin-left: 20%;
				color: #FFFFFF;

				p {
					font-size: 16px;

					span {
						font-size: 22px;
					}
				}

				#timacard {
					width: 100%;
					height: 350px;
					background-color: #FFFFFF;
					border-radius: 8px;
					text-align: center;

					img {
						height: 90%;
						max-width: 90%;
					}
				}
			}
		}

		#bgcci {
			width: 100%;
			height: 1px;
			background-color: #000000;
			position: relative;
			z-index: -3;

			div {
				width: 100%;
				height: 300px;
				background-color: #f3f3f3;
				position: absolute;
				top: -610px;

			}
		}
	}

	@media screen and (max-width:720px) {

		//手机
		#bgcbox {
			width: 100%;
			height: 1840px;
			background-image: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
			position: relative;
			top: -615px;
			z-index: -1;
			padding-top: 650px;
		
			#bgcbox_header {
				width: 100%;
				height: 10%;
				text-align: center;
		
				img {
					// height: 50%;
					width: 15%;
				}
		
				p {
					font-size: 30px;
					color: #FFFFFF;
				}
			}
		
			#timecard_box {
				width: 100%;
				color: #FFFFFF;
		
				p {
					font-size: 16px;
		
					span {
						font-size: 22px;
					}
				}
		
				#timacard {
					width: 100%;
					height: 200px !important;
					background-color: #FFFFFF;
					border-radius: 8px;
					text-align: center;
		
					img {
						width: 90%;
					}
				}
			}
		}
		
		#bgcci {
			width: 100%;
			height: 1px;
			background-color: #000000;
			position: relative;
			z-index: -3;
		
			div {
				width: 100%;
				height: 300px;
				background-color: #f3f3f3;
				position: absolute;
				top: -610px;
		
			}
		}

	}

	@media screen and (min-width:720px) {

		//电脑
		#WXBOX {
			width: 100%;
			height: 1800px;
			background-color: #f3f3f3;
			margin-top: -315px;
			padding-top: 5%;
			z-index: 9;

			#WXBOX_header {
				width: 60%;
				height: 20%;
				margin-left: 20%;
				background-color: #ffffff;
				text-align: center;
				border-radius: 10px 10px 0px 0px;

				img {
					height: 40%;
				}

				p {
					font-size: 26px;
					color: #fd3f31;
				}

				span {
					font-size: 19px;
				}
			}

			#img_flex {
				width: 60%;
				margin-left: 20%;
				height: 50%;
				background-color: #FFFFFF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				text-align: center;

				#img_flex_box {
					width: 30%;
					height: 49%;

					img {
						max-height: 80%;
						max-width: 45%;
						box-shadow: 7px 5px 7px #d6d4d4;
					}

					p {
						margin-top: 5%;
						font-weight: bold;
					}
				}
			}

			#WXBOX_bottom {
				width: 60%;
				height: 29%;
				margin-left: 20%;
				background: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
				border-radius: 0px 0px 10px 10px;
				text-align: center;
				padding-top: 3%;

				.tt {
					font-size: 22px;
					color: #FFFFFF;
				}

				#WXBOX_bottom_flex {
					width: 100%;
					height: 85%;
					margin-top: 2%;
					display: flex;

					#WXBOX_bottom_LEFT {
						width: 50%;
						height: 100%;

						img {
							height: 80%;
						}

						p {
							font-size: 18px;
							margin-top: 5%;
							color: #FFFFFF;
						}
					}

				}

			}
		}

	}

	@media screen and (max-width:720px) {

		//手机
		#WXBOX {
			width: 100%;
			height: 1800px;
			background-color: #f3f3f3;
			margin-top: -615px;
			padding-top: 5%;
			z-index: 9;
		
			#WXBOX_header {
				width: 100%;
				height: 10%;
				background-color: #ffffff;
				text-align: center;
				border-radius: 10px 10px 0px 0px;
		
				img {
					height: 33%;
					max-width: 50%;
				}
		
				p {
					font-size: 26px;
					color: #fd3f31;
				}
		
				span {
					font-size: 18px;
				}
			}
		
			#img_flex {
				width: 100%;
				height: 50%;
				background-color: #FFFFFF;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				text-align: center;
		
				#img_flex_box {
					width: 50%;
					height: 30%;
					img {
						max-height: 80%;
						max-width: 45%;
						box-shadow: 7px 5px 7px #d6d4d4;
					}
		
					p {
						margin-top: 5%;
						font-weight: bold;
					}
				}
			}
		
			#WXBOX_bottom {
				width: 100%;
				height: 29%;
				background: linear-gradient(to left bottom, rgba(73, 31, 237, 0.6) 0%, rgba(130, 190, 253, 0.6) 100%);
				border-radius: 0px 0px 10px 10px;
				text-align: center;
				padding-top: 3%;
		
				.tt {
					font-size: 20px;
					color: #FFFFFF;
				}
		
				#WXBOX_bottom_flex {
					width: 100%;
					height: 75%;
					margin-top: 15%;
					display: flex;
		
					#WXBOX_bottom_LEFT {
						width: 50%;
						height: 100%;
		
						img {
							height: 60%;
							width: 90%;
						}
		
						p {
							font-size: 18px;
							margin-top: 5%;
							color: #FFFFFF;
						}
					}
		
				}
		
			}
		}

	}

	@media screen and (min-width:720px) {

		//电脑
		#onestore {
			width: 100%;
			height: 1100px;
			background-color: #F9F9F9;

			#onestore_main {
				width: 60%;
				margin-left: 20%;
				height: 100%;

				#onestore_main_header {
					width: 100%;
					height: 20%;
					text-align: center;

					#lins {
						width: 20px;
						height: 4px;
						border-radius: 10px;
						background-color: #fd3f31;
						margin: 0 auto;
					}

					p {
						font-size: 36px;
						margin-top: 3%;
						color: #fd3f31;
					}

					span {
						font-size: 22px;
					}
				}

				#onestore_main_main {
					width: 100%;
					height: 79%;
					background-color: #F9F9F9;

					#onestore_main_main_flex {
						width: 90%;
						margin-left: 5%;
						height: 35%;
						display: flex;
						justify-content: space-between;

						#onestore_main_main_flex_box {
							width: 30%;
							height: 100%;
							background-color: #FFFFFF;
							border-radius: 20px;
							box-shadow: 1px 3px 11px #d2cccc;
							text-align: center;

							img {
								height: 40%;
								margin-top: 15%;
								max-width: 100%;
							}

							p {
								font-weight: bold;
								font-size: 22px;
							}
						}
						
					}

					#immage {
						width: 90%;
						height: 28%;
						margin-top: 2%;
						text-align: center;

						img {
							width: 100%;
							max-height: 100%;
							margin-left: 5%;
						}
					}
				}
			}
		}

	}

	@media screen and (max-width:720px) {

		//手机
		#onestore {
			width: 100%;
			height: 680px;
			background-color: #F9F9F9;
			margin-top: -40%;
			#onestore_main {
				width: 100%;
				height: 100%;
		
				#onestore_main_header {
					width: 100%;
					height: 20%;
					text-align: center;
		
					#lins {
						width: 20px;
						height: 4px;
						border-radius: 10px;
						background-color: #fd3f31;
						margin: 0 auto;
					}
		
					p {
						font-size: 30px;
						margin-top: 3%;
						color: #fd3f31;
					}
		
					span {
						font-size: 16px;
					}
				}
		
				#onestore_main_main {
					width: 100%;
					height: 79%;
					background-color: #F9F9F9;
		
					#onestore_main_main_flex {
						width: 100%;
						height: 35%;
						display: flex;
						justify-content: space-between;
		
						#onestore_main_main_flex_box {
							width: 30%;
							height: 95%;
							background-color: #FFFFFF;
							border-radius: 20px;
							box-shadow: 1px 3px 11px #d2cccc;
							text-align: center;
		
							img {
								height: 20%;
								margin-top: 15%;
								max-width: 100%;
							}
		
							p {
								font-weight: bold;
								font-size: 16px;
								height: 25%;
								display: flex;
								justify-content: center;
								align-items: center;
							}
							span{
								font-size: 12px;
							}
						}
						#onestore_main_main_flex_box:hover{
							width: 32%;
							height: 100%;
						}
					}
		
					#immage {
						width: 100%;
						height: 20%;
						margin-top: 2%;
						text-align: center;
		
						img {
							width: 100%;
							max-height: 100%;
						}
					}
				}
			}
		}

	}

	@media screen and (min-width:720px) {

		//电脑
		#right_box_float {
			width: 100px;
			height: 400px;
			position: fixed;
			top: 30vh;
			left: 90%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;

			#right_box_float_box {
				width: 100px;
				height: 100px;
				background-color: rgba(0, 0, 0, 0.3);
				border-radius: 50%;
				text-align: center;
				line-height: 110px;
				cursor: pointer;

				.iconclas {
					display: inline-block;

					font-size: 50px;
					color: #d5d5d5;
				}
			}
		}

		#left_box_float {
			width: 10%;
			height: 400px;
			position: fixed;
			top: 25%;
			left: 10%;
		}

		/deep/.el-timeline-item__content {
			color: #8387dd;
			font-size: 18px;
			min-width: 100px;
			cursor: pointer;
		}

		/deep/.el-timeline-item {
			padding-bottom: 1px;
		}
	}

	@media screen and (max-width:720px) {
		//手机
		#right_box_float{
			display: none;
		}
		#left_box_float{
			display: none;
		}
	}
</style>
s